import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_IMG_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export default {
  auth(url = 'users') {
    return {
      login: ({ email, password }) => http.post(url + '/authenticate', {
        username: email,
        password: password
      }),
      register: ({ email, name, password }) => http.post(url + '/register', { email, name, password })
    }
  },

  map(url = 'map') {
    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/list', config),
      fetchPagination: (page, limit, name, category) =>
        http.get(url + "?page=" + page + "&limit=" + limit + "&name=" + name + "&category=" + category, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url, newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/" + id, updatedRecord, config),
      delete: id => http.delete(url + "/" + id, config)
    }
  },

  user(url = 'users') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, firstName = null, email = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&firstName=" + firstName + "&email=" + email, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/register', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  feedback(url = 'feedbacks') {

    const config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, firstName = null, email = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&firstName=" + firstName + "&email=" + email, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      updateCart: (id, updatedRecord) => http.post(url + "/updateCart/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      getByServiceId: id => http.get(url + "/getByServiceId/" + id, config),
    }
  },
  booking(url = 'bookings') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, type) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  category(url = 'category') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  department(url = 'departments') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  result(url = 'results') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  contact(url = 'contacts') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, mobile = null, email =null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name + "&mobile=" + mobile+ "&email=" + email, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  doctor(url = 'doctors') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, type) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name+ "&type=" + type, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  blog(url = 'blogs') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, type) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  pack(url = 'packs') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, type) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  fcm(url = 'fcms') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  branche(url = 'branches') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, name = null, email = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&name=" + name + "&email=" + email, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  doctorbooking(url = 'doctorbookings') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      getFeatured: (param) => http.post(url + '/getFeatured',param, config),
      fetchPagination: (page, limit = 10, status = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&status=" + status, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  offer(url = 'offers') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  giftcard(url = 'giftcards') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  service(url = 'services') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/getallproducts', config),
      getFilteredAllServices: (param) => http.post(url + '/getFilteredAllServices',param, config),
      fetchPagination: (page = 1, limit = 10, title, price) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&price=" + price + "&type=" + "product", config),
      fetchPagfetchPackagesPaginationination: (page = 1, limit = 10, title, price) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&price=" + price + "&type=" + "package", config),
        fetchProductsPagination: (page = 1, limit = 10, title, price) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&price=" + price + "&type=" + "item", config),
        fetchCouponsPagination: (page = 1, limit = 10, title, price) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&price=" + price + "&type=" + "coupon", config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      getCart: cartArray => http.post(url + '/getCart', cartArray, config),
    }
  },

  promocode(url = 'promo_codes') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, promoCode = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&promoCode=" + promoCode + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  coupon(url = 'coupons') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  giftcardorder(url = 'giftcardorders') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchTopSlider: () => http.get(url + '/getTopSliders', config),
      fetchBottomSlider: () => http.get(url + '/getBottomSliders', config),
      fetchPagination: (page, limit = 10, rating = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&rating=" + rating, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },


  event(url = 'events') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  unit(url = 'units') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  log(url = 'logs') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: (param) => http.post(url + '/all', param, config),
      fetchPagination: (page, limit = 10, title = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },
  order(url = 'order') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      getCount: () => http.get(url + '/getCount', config),
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, transaction_no = null, customer_name = null, FilterTodays) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&transaction_no=" + transaction_no + "&customer_name=" + customer_name + "&FilterTodays=" + FilterTodays, config),
      fetchById: id => http.get(url + "/single/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      getMyOrders: id => http.get(url + '/getMyOrders/'+ id, config),
      getAvailableTimeSlots: param => http.post(url + '/getAvailableTimeSlots', param, config),
    }
  },
  review(url = 'reviews') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, rating = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&rating=" + rating, config),
      fetchById: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.put(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config)
    }
  },

  notifications(url = 'notification') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      fetchAll: () => http.get(url + '/all', config),
      fetchPagination: (page, limit = 10, title = null, description = null) =>
        http.get(url + '/findPagination' + "?page=" + page + "&limit=" + limit + "&title=" + title + "&description=" + description, config),
      getMyNotifications: id => http.get(url + "/" + id, config),
      create: newRecord => http.post(url + '/addnew', newRecord, config),
      update: (id, updatedRecord) => http.post(url + "/update/" + id, updatedRecord, config),
      delete: id => http.put(url + "/remove/" + id, {}, config),
      getMyNotifications: id => http.get(url + "/" + id, config),
    }
  },

  utility(url = 'utility') {

    const config = {
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    return {
      upload: (file) => http.post(url + '/upload', file, config),
    }
  },
  notification() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "key=AAAAM2TkNWk:APA91bET4-uzLsF2s_ola_KDhz4e_k0eJ4UZaF_U-DHWoC5d4d9DhSREGDoNE4eLl182OtQBvggUslpGycli1IzlASN2z0NF-rTmnX7iK7TMWOMyHG4yoUvJz3HB34EQYpMz0yVpHgoA"
      }
    };

    return {
      sendPushNotification: (notificationParam) => http.post('https://fcm.googleapis.com/fcm/send', notificationParam, config),
    }
  },

}