import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";

import {
  Home as HomeIcon,
  LibraryBooks as LibraryIcon,
  ArrowBack as ArrowBackIcon,
  Person as AccountIcon,
  GroupOutlined as GroupOutlinedIcon,
  CategoryOutlined as CategoryOutlinedIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
  BookmarksOutlined as BookmarksOutlinedIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  MenuOpenOutlined as MenuOpenOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon
} from "@material-ui/icons";

import PublicIcon from '@material-ui/icons/Public';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";
import "./scrollbar.css";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 24, label: "Bookings", link: "/admin/booking", icon: <LocalOfferOutlinedIcon /> },
  { id: 10, label: "Banners", link: "/admin/offer", icon: <LocalOfferOutlinedIcon /> },
  { id: 11, label: "Departments", link: "/admin/department", icon: <LocalOfferOutlinedIcon /> },
  { id: 3, label: "Category", link: "/admin/category", icon: <CategoryOutlinedIcon /> },
  { id: 5, label: "Specialities", link: "/admin/service", icon: <BookmarkBorderOutlinedIcon /> },
  { id: 14, label: "Insurance", link: "/admin/unit", icon: <LocalOfferOutlinedIcon /> },
  { id: 4, label: "Doctors", link: "/admin/doctor", icon: <LocalHospitalOutlinedIcon /> },
  { id: 22, label: "Blog", link: "/admin/blog", icon: <LocalHospitalOutlinedIcon /> },
  { id: 28, label: "Offers", link: "/admin/pack", icon: <LocalHospitalOutlinedIcon /> },
  { id: 23, label: "Patient stories", link: "/admin/result", icon: <LocalHospitalOutlinedIcon /> },
  { id: 25, label: "Reviews", link: "/admin/feedback", icon: <LocalHospitalOutlinedIcon /> },
  { id: 8, label: "Testimonials", link: "/admin/event", icon: <DateRangeOutlinedIcon /> },
  { id: 19, label: "Staff", link: "/admin/staff", icon: <LocalHospitalOutlinedIcon /> },
  { id: 13, label: "Branches", link: "/admin/branche", icon: <LocalOfferOutlinedIcon /> },
  { id: 6, label: "Packages", link: "/admin/package", icon: <BookmarksOutlinedIcon /> },
  // { id: 4, type: "divider" },
  // { id: 5, type: "title", label: "Disabled Modules" },
  // { id: 0, label: "Dashboard", link: "/admin/dashboard", icon: <HomeIcon /> },
  // { id: 1, label: "Orders", link: "/admin/order", icon: <ShoppingCartOutlinedIcon /> },
  // { id: 17, label: "Doctor Appointments", link: "/admin/doctorbooking", icon: <LocalHospitalOutlinedIcon /> },
  // { id: 2, label: "User Management", link: "/admin/user", icon: <GroupOutlinedIcon /> },
  
  // { id: 12, label: "Deals", link: "/admin/scoupon", icon: <LocalOfferOutlinedIcon /> },

  // { id: 7, label: "Promocode", link: "/admin/promocode", icon: <LocalOfferOutlinedIcon /> },
  // { id: 9, label: "App Feedbacks", link: "/admin/review", icon: <DateRangeOutlinedIcon /> },
  
  // { id: 2, label: "User (context)", link: "/admin/usercontext", icon: <AccountIcon /> },
  // { id: 16, label: "Products", link: "/admin/product", icon: <BookmarksOutlinedIcon /> },
  // { id: 3, label: "Map (redux)", link: "/admin/map", icon: <PublicIcon /> },

  // { id: 6, label: "Documents", link: "", icon: <LibraryIcon /> },

  //{ id: 15, label: "Notification Center", link: "/admin/notificationcenter", icon: <LocalOfferOutlinedIcon /> },
  // { id: 15, label: "Notification Center", link: "/admin/fcm", icon: <LocalHospitalOutlinedIcon /> },
   { id: 18, label: "Contacts Form", link: "/admin/contact", icon: <LocalOfferOutlinedIcon /> },
  // { id: 20, label: "Giftcards", link: "/admin/giftcard", icon: <LocalHospitalOutlinedIcon /> },
  // { id: 21, label: "Giftcard Orders", link: "/admin/giftcardorder", icon: <LocalHospitalOutlinedIcon /> },

];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <div>
        <List className={classes.sidebarList}>
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      </div>
      
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
