import { combineReducers } from "redux";
import { map } from "./map";
import { user } from "./user";
import { category } from "./category";
import { doctor } from "./doctor";
import { service } from "./service";
import { promocode } from "./promocode";
import { event } from "./event";
import { order } from "./order";
import { review } from "./review";
import { offer } from "./offer";
import { coupon } from "./coupon";
import { department } from "./department";
import { branche } from "./branche";
import { unit } from "./unit";
import { fcm } from "./fcm";
import { doctorbooking } from "./doctorbooking";
import { contact } from "./contact";
import { giftcard } from "./giftcard";
import { giftcardorder } from "./giftcardorder";
import { blog } from "./blog";
import { result } from "./result";    
import { booking } from "./booking"; 
import { feedback } from "./feedback"; 
import { pack } from "./pack";      

export const reducers = combineReducers({
    map, user, category, doctor, service, promocode, event, order, review, offer, coupon, department, branche, unit, fcm, doctorbooking, contact, giftcard, giftcardorder, blog, result, booking, feedback, pack
})