import { ACTION_TYPES } from "../actions/pack";

const initialState = {
    packs: [],
    metaPack: {}
}

export const pack = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.PACK_FETCH_ALL:
            return {
                ...state,
                packs: [...action.payload]
            }
        case ACTION_TYPES.PACK_CREATE:
            return {
                ...state,
                packs: [...state.packs, action.payload]
            }
        case ACTION_TYPES.PACK_UPDATE:
            return {
                ...state,
                packs: state.packs.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.PACK_DELETE:
            return {
                ...state,
                packs:state.packs.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.PACK_PAGINATION:
      
            return {
                ...state,
                packs: [...action.payload.packs],
                metaPack: action.payload.meta
            }
        default:
            return state;
    }
}