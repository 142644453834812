import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/pack";
import * as category  from "../../../actions/category";
import * as department  from "../../../actions/department";
import FormDialogAddPack from "../formDialog/FormDialogAddPack";
import FormDialogEditPack from "../formDialog/FormDialogEditPack";
import FormDialogDeletePack from "../formDialog/FormDialogDeletePack";
import * as branche  from "../../../actions/branche";
import * as doctor from "../../../actions/doctor";
import * as service from "../../../actions/service";


const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const PackTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [category, setCategory] = useState({})
    const [isLoading, setIsloading] = useState(false)
    useEffect(() => {     
        props.fetchCategories();
        props.fetchPagination(1, rowsPerPage, '')
        props.fetchDepartments();
        props.fetchBranches();
        props.fetchDoctors();
        props.fetchServices();

    }, [])

    const handleChangePage = async (newPage) => {
        setIsloading(true)
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage, '')
        setIsloading(false)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        setIsloading(true)
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '')
        setIsloading(false)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText)
    };

    const handleFilterChange = async (title) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '')
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, '')
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
            customHeadRender: (columnMeta, handleToggleColumn) => {
                return (
                    <th key={columnMeta.index} 
                        style={{
                            paddingLeft: "31px", 
                            fontWeight:500, 
                            borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                            }}
                    >
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                            {columnMeta.label}
                        </div>
                    </th>
                );
            },
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <span style={{marginLeft:15}}>
                      {value}
                    </span>
                );
            }
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} 
                            style={{
                                paddingLeft: "31px", 
                                fontWeight:500, 
                                borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                                }}
                        >
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value.en}
                        </span>
                    );
                }
            },
            
        },
      
        {
            name: "categoryId",
            label: "Category",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "about",
            label: "About",
            options: {
                filter: false,
                sort: false,
                display: false,
                default:{en:'', ar:''}
            }
        },
        {
            name: "departments",
            label: "Departments",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "branch",
            label: "Branch",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "doctor",
            label: "doctor",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "gallary",
            label: "gallary",
            options: {
                filter: false,
                sort: false,
                display: false,
                default:[]
            },
        
        },
        {
            name: "about1",
            label: "about1",
            options: {
                filter: false,
                sort: false,
                display: false,
                default:{en:'', ar:''}
            },
        
        },
        {
            name: "keywords",
            label: "keywords",
            options: {
                filter: false,
                sort: false,
                display: false,
                default:[]
            },
        
        },
        {
            name: "service",
            label: "service",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "seoh1",
            label: "seoh1",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "seotitle",
            label: "seotitle",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "seometa",
            label: "seometa",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddPack component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    categories ={ props.categories}
                                    departments ={ props.departments}
                                    branches ={ props.branches}
                                    doctors ={ props.doctors}
                                    services ={ props.services}

                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            <FormDialogEditPack
                                dataPack={tableMeta.rowData}
                                update={props.update}
                                categories ={ props.categories}
                                departments ={ props.departments}
                                branches ={ props.branches}
                                doctors ={ props.doctors}
                                services ={ props.services}

                            />
                            <FormDialogDeletePack 
                                dataPack={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        rowsPerPageOptions: [10, 20, 30],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,
        textLabels: {
            body: {
                noMatch: isLoading ?
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
                </div>:
                    'Sorry, there is no matching data to display',
            },
        },
        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (

<MUIDataTable className={classes.paperTable}
            data={props.packs}
            columns={columns}
            options={options}
        />
     
        
    );
}

const mapStateToProps = state => ({
    packs: state?.pack?.packs,
    meta: state?.pack?.metaPack,
    categories: state.category.categories,
    departments: state.department.departments,
    branches: state.branche.branches,
    doctors: state.doctor.doctors,
    services: state?.service?.services,

})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchCategories : category.fetchAll,
    fetchDepartments : department.fetchAll,
    fetchBranches : branche.fetchAll,
    fetchDoctors : doctor.fetchAll,
    fetchServices : service.fetchAll,

}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(PackTable));