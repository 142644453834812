import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  name: { en: '', ar: '' },
  categoryId: [],
  about: { en: '', ar: '' },
  departments: [],
  doctor: '',
  gallary: [],
  about1: { en: '', ar: '' },
  keywords: "",
  service: "",
  seoh1: { en: "", ar: "" },
  seotitle: { en: "", ar: "" },
  seometa: { en: "", ar: "" },
}

const FormDialogEditPack = (props) => {
  const [open, setOpen] = useState(false);
  const [pack, setPack] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const [selectedLang, setSelectedLang] = useState('en')
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString(props?.dataPack[4]?.[selectedLang], 'html'))
  const [htmlDesc11, setHtmlDesc11] = useState(props?.dataPack[9] ? RichTextEditor.createValueFromString(props?.dataPack[9]?.[selectedLang], 'html') : RichTextEditor.createValueFromString('', 'html'))
  const handleClickOpen = () => {
    console.log('props.dataPack',props.dataPack)

    API.pack().fetchById(props.dataPack[0])
      .then(res => {

        setPack({
          ...pack,
          id: props.dataPack[0],
          image: props.dataPack[1],
          name: props.dataPack[2],
          categoryId: props?.dataPack[3]?._id,
          about: res.data.about,
          departments: props.dataPack[5] ? props.dataPack[5] : [],
          branch: props.dataPack[6],
          doctor: props?.dataPack[7]?._id,
          gallary: props?.dataPack[8],
          about1: res.data.about1,
          keywords: props?.dataPack[10],
          service: props?.dataPack[11]?._id,
          seoh1: props.dataPack[12] ? props.dataPack[12] : { en: "", ar: "" },
          seotitle: props.dataPack[13] ? props.dataPack[13] : { en: "", ar: "" },
          seometa: props.dataPack[14] ? props.dataPack[14] : { en: "", ar: "" },
        })
      
        if (selectedLang === 'ar') {
          setHtmlDesc(RichTextEditor.createValueFromString(res.data.about.ar, 'html'))
          setHtmlDesc11(RichTextEditor.createValueFromString(res.data.about1.ar, 'html'))
        } else {
          setHtmlDesc(RichTextEditor.createValueFromString(res.data.about.en, 'html'))
          setHtmlDesc11(RichTextEditor.createValueFromString(res.data.about1.en, 'html'))
        }

      })
      .catch(err => console.log(err))

    setErrors({});
    // setPack({...pack,
    //   id: props.dataPack[0],
    //   image: props.dataPack[1],
    //   name: props.dataPack[2],
    //   categoryId: props?.dataPack[3]?._id,
    //  // about: props?.dataPack[4],
    //   departments: props.dataPack[5]?props.dataPack[5]:[],
    //   branch: props.dataPack[6],
    //   doctor: props?.dataPack[7],
    //   gallary: props?.dataPack[8],
    //  // about1: props?.dataPack[9],
    //   keywords: props?.dataPack[10],
    // })

    setOpen(true);
    console.log(pack)
  }

  const handleClose = () => {
    setOpen(false);
    console.log(pack)
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setPack({
        ...pack,
        [name]: { ...pack[name], [selectedLang]: value }
      });

    } else {
      setPack({ ...pack, [name]: value })
    }
  }

  useEffect(() => {
 
    if (pack.about.ar || pack.about.en) {
      if (selectedLang === 'ar') {
        setHtmlDesc(RichTextEditor.createValueFromString(pack.about.ar, 'html'))
        setHtmlDesc11(RichTextEditor.createValueFromString(pack.about1.ar, 'html'))
      } else {
        setHtmlDesc(RichTextEditor.createValueFromString(pack.about.en, 'html'))
        setHtmlDesc11(RichTextEditor.createValueFromString(pack.about1.en, 'html'))
      }
    }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)
    //  setPack({ ...pack, about: value.toString('html') })
    setPack({
      ...pack,
      ['about']: { ...pack['about'], [selectedLang]: value.toString('html') }
    });
  }
  const handleChangeForDoctor = (event) => {
    setPack({ ...pack, doctor: event.target.value })

  };
  const handleChangeForService = (event) => {
    setPack({ ...pack, service: event.target.value })

  };
  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 350) {
      toast.warning('maximum size allowed: 350kb');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setPack({ ...pack, image: res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!pack.image || pack.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.warning('Image cannot be empty');
    }

    if (!pack.categoryId) {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
      toast.warning('Category cannot be empty');
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleChange = (event) => {
    setPack({ ...pack, categoryId: event.target.value })

  };
  const handleBranchChangeForServices = (event) => {
    setPack({ ...pack, branch: event.target.value })

  };
  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(pack.id, pack, onSuccess)
    }
  }

  const handleChangeForDepartments = (event) => {
    setPack({ ...pack, departments: event.target.value })

  };
  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setPack({ ...pack, gallary: [...pack.gallary, res.data.data.path] })

        console.log(pack)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = pack.gallary

    gal.splice(i, 1)

    setPack({ ...pack, gallary: gal })

    console.log(pack)

  }
  const handleChangeForThumbnail = (img) => {

    setPack({ ...pack, image: img })

  };

  const handleHtmlInputChange11 = (value, name) => {

    setHtmlDesc11(value)
    setPack({
      ...pack,
      [name]: { ...pack[name], [selectedLang]: value.toString('html') }
    });

    console.log(pack)
  }
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit pack</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


<div className="row">
  {/* <div className="col-lg-12 col-12 mb-4">
    <Button variant="contained" component="label">
      Upload Picture <sub>&nbsp; 150kb max</sub>
      <input
        type="file"
        name="file"
        label="Image"
        accept=".png,.jpg,.gif"
        onChange={handleInputChangeForFileType}
        {...(errors.file && { error: true, helperText: errors.file })}
        hidden
      />
    </Button>
    <img src={process.env.REACT_APP_IMG_URL + pack.image} height="100" style={{ marginTop: "-30px", float: "right" }} />
  </div> */}
  <div className="col-lg-3 col-12 mb-4">
    <Button variant="contained" component="label">
      Upload Picture in Gallary
      <input
        type="file"
        name="file"
        label="Image"
        onChange={handleInputChangeForGallary}
        {...(errors.file && { error: true, helperText: errors.file })}
        hidden
      />
    </Button>
    {/* <div className="mb-1">Gallary</div>
<TextField
autoFocus
type="file"
name="file"
className="border"
onChange={handleInputChangeForGallary}
{...(errors.file && { error: true, helperText: errors.file })}
/> */}
  </div>
  <div className="col-lg-9 col-12">
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {pack?.gallary?.map((img, i) => (
        <Grid item xs={2} sm={2} md={2} key={i}>

          <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="100" style={{ marginRight: "10px" }} />
          <FormControlLabel control={
            <Checkbox
              checked={pack.image === img}
              onChange={() => handleChangeForThumbnail(img)}
            />
          } label="Set Thumb" />

          <a onClick={() => deleteFromGallary(i)}>
            <DeleteIcon />
          </a>

        </Grid>
      ))}
    </Grid>
  </div>
</div>

<div className="row border p-2">

  <div className="col-lg-12 col-12 mb-4">
    <FormControl className="float-end">
      <InputLabel id="demo-simple-select-label">Language</InputLabel>
      <Select
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value)}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
    </FormControl>
  </div>

  <div className="col-lg-12 col-12 mb-4">
    <TextField

      name="name"
      label="Name"
      value={pack?.name[selectedLang]}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}
      {...(errors.name && { error: true, helperText: errors.name })}
    />
  </div>




  <div className="col-lg-12 col-12 mb-4">
    <p>Section 1 </p>
    <RichTextEditor
      value={htmlDesc}
      onChange={handleHtmlInputChange}
    />
  </div>

  {/* <div className="col-lg-12 col-12 mb-4">
    <p>Section 2</p>
    <RichTextEditor
      value={htmlDesc11}
      onChange={(val) => handleHtmlInputChange11(val, 'about1')}
    />
  </div> */}

  <div className="col-lg-12 col-12 mb-4">
    <TextField

      name="keywords"
      label="keywords"
      value={pack?.keywords?.en ? pack?.keywords[selectedLang] : ''}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}

      {...(errors.keywords && { error: true, helperText: errors.keywords })}
    />
  </div>
  <div className="col-lg-6 col-12 mb-4">
    <TextField
      name="seoh1"
      label="SEO h1"

      value={pack?.seoh1[selectedLang]}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}
      {...(errors.seoh1 && { error: true, helperText: errors.seoh1 })}
    />

  </div>

  <div className="col-lg-6 col-12 mb-4">
    <TextField
      name="seotitle"
      label="SEO Title"

      value={pack?.seotitle[selectedLang]}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}
      {...(errors.seotitle && { error: true, helperText: errors.seotitle })}
    />

  </div>

  <div className="col-lg-12 col-12 mb-4">
    <TextField
      name="seometa"
      label="SEO Meta"

      value={pack?.seometa[selectedLang]}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}
      {...(errors.seometa && { error: true, helperText: errors.seometa })}
    />

  </div>
</div>



<br></br>



<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={pack.categoryId}
    onChange={handleChange}

  >
    {props.categories.map(item => (
      <MenuItem value={item.id}>{item.title?.en}</MenuItem>
    ))}

  </Select>
</FormControl>


<br /><br />

{/* <FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Departments</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={pack.departments}
    onChange={handleChangeForDepartments}
    multiple
  >
    {props.departments.map(item => (
      <MenuItem value={item.id}>{item.title.en}</MenuItem>
    ))}

  </Select>
</FormControl>


<br /><br /> */}



{/* <FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={pack.branch}
    onChange={handleBranchChangeForServices}

  >
    {props.branches.map(item => (
      <MenuItem value={item.id}>{item.name}</MenuItem>
    ))}

  </Select>
</FormControl>

<br /><br /> */}


<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={pack.doctor}
    onChange={handleChangeForDoctor}

  >
    {props.doctors.filter((e) => e.role === 'doctor').map(item => (
      <MenuItem value={item.id}>{item.name?.en}</MenuItem>
    ))}

  </Select>
</FormControl>
<br /><br />
{/* <FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Choose Service</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={pack.service}
    onChange={handleChangeForService}

  >
    {props.services.map(item => (
      <MenuItem value={item.id}>{item.title?.en}</MenuItem>
    ))}

  </Select>
</FormControl>
<br /><br /> */}
</DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditPack;