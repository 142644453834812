import { ACTION_TYPES } from "../actions/ad";

const initialState = {
    ads: [],
    metaAd: {}
}

export const ad = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.AD_FETCH_ALL:
            return {
                ...state,
                ads: [...action.payload]
            }
        case ACTION_TYPES.AD_CREATE:
            return {
                ...state,
                ads: [...state.ads, action.payload]
            }
        case ACTION_TYPES.AD_UPDATE:
            return {
                ...state,
                ads: state.ads.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.AD_DELETE:
            return {
                ...state,
                ads:state.ads.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.AD_PAGINATION:
      
            return {
                ...state,
                ads: [...action.payload.ads],
                metaAd: action.payload.meta
            }
        default:
            return state;
    }
}