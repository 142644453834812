import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  name: "",
  categoryId: [],
  about: "",
  departments: [],
  doctor: '',
  gallary: [],
  about1: "",
  keywords: { en: '', ar: '' },
  service: "",
  seoh1: { en: "", ar: "" },
  seotitle: { en: "", ar: "" },
  seometa: { en: "", ar: "" },
}

const FormDialogAddAd = (props) => {
  const [open, setOpen] = useState(false);
  const [ad, setAd] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc11, setHtmlDesc11] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
    setErrors({});
    setAd(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setAd({
        ...ad,
        [name]: { ...ad[name], [selectedLang]: value }
      });

    } else {
      setAd({ ...ad, [name]: value })
    }
  }

  useEffect(() => {
 
    if (ad.about.ar || ad.about.en) {
      if (selectedLang === 'ar') {
        setHtmlDesc(RichTextEditor.createValueFromString(ad.about.ar, 'html'))
        setHtmlDesc11(RichTextEditor.createValueFromString(ad.about1.ar, 'html'))
      } else {
        setHtmlDesc(RichTextEditor.createValueFromString(ad.about.en, 'html'))
        setHtmlDesc11(RichTextEditor.createValueFromString(ad.about1.en, 'html'))
      }
    }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)
    //  setAd({ ...ad, about: value.toString('html') })
    setAd({
      ...ad,
      ['about']: { ...ad['about'], [selectedLang]: value.toString('html') }
    });
  }

  const handleInputChangeForFileType = event => {
    if (event.target.files[0].size / 1024 > 350) {
      toast.warning('maximum size allowed: 350kb');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setAd({ ...ad, image: res.data.data.path })
      })

  }
  const handleChangeForDoctor = (event) => {
    setAd({ ...ad, doctor: event.target.value })

  };

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!ad.image || ad.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.warning('Image cannot be empty');
    }


    if (!ad.categoryId) {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
      toast.warning('Category cannot be empty');
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
      window.location.reload();
    }
    e.preventDefault();

    if (validate()) {
      props.create(ad, onSuccess)
    }
  }

  const handleChange = (event) => {
    setAd({ ...ad, categoryId: event.target.value })

  };

  const handleChangeForDepartments = (event) => {
    setAd({ ...ad, departments: event.target.value })

  };
  const handleChangeForService = (event) => {
    setAd({ ...ad, service: event.target.value })

  };
  const handleBranchChangeForServices = (event) => {
    setAd({ ...ad, branch: event.target.value })

  };
  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setAd({ ...ad, gallary: [...ad.gallary, res.data.data.path] })

        console.log(ad)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = ad.gallary

    gal.splice(i, 1)

    setAd({ ...ad, gallary: gal })

    console.log(ad)

  }
  const handleChangeForThumbnail = (img) => {

    setAd({ ...ad, image: img })

  };

  const handleHtmlInputChange11 = (value, name) => {

    setHtmlDesc11(value)
    setAd({
      ...ad,
      [name]: { ...ad[name], [selectedLang]: value.toString('html') }
    });
  }
  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add Campaign page</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


          <div className="row">
            {/* <div className="col-lg-12 col-12 mb-4">
              <Button variant="contained" component="label">
                Upload Picture <sub>&nbsp; 150kb max</sub>
                <input
                  type="file"
                  name="file"
                  label="Image"
                  accept=".png,.jpg,.gif"
                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button>
              <img src={process.env.REACT_APP_IMG_URL + ad.image} height="100" style={{ marginTop: "-30px", float: "right" }} />
            </div> */}
            <div className="col-lg-3 col-12 mb-4">
              <Button variant="contained" component="label">
                Upload Picture in Gallary
                <input
                  type="file"
                  name="file"
                  label="Image"
                  onChange={handleInputChangeForGallary}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button>
              <div className="mt-3 text-sm">Width : 730px height: 300px 100kb max</div>
              {/* <div className="mb-1">Gallary</div>
<TextField
  autoFocus
  type="file"
  name="file"
  className="border"
  onChange={handleInputChangeForGallary}
  {...(errors.file && { error: true, helperText: errors.file })}
/> */}
            </div>
            <div className="col-lg-9 col-12">
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {ad?.gallary?.map((img, i) => (
                  <Grid item xs={2} sm={2} md={2} key={i}>

                    <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="100" style={{ marginRight: "10px" }} />
                    <FormControlLabel control={
                      <Checkbox
                        checked={ad.image === img}
                        onChange={() => handleChangeForThumbnail(img)}
                      />
                    } label="Set Thumb" />

                    <a onClick={() => deleteFromGallary(i)}>
                      <DeleteIcon />
                    </a>

                  </Grid>
                ))}
              </Grid>
            </div>
          </div>

          <div className="row border p-2">

            <div className="col-lg-12 col-12 mb-4">
              <FormControl className="float-end">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="name"
                label="Name"
                value={ad?.name[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.name && { error: true, helperText: errors.name })}
              />
                            <div className="mt-2">No special characters</div>

            </div>




            <div className="col-lg-12 col-12 mb-4">
              <p>Section 1 </p>
              <RichTextEditor
                value={htmlDesc}
                onChange={handleHtmlInputChange}
              />
            </div>

            {/* <div className="col-lg-12 col-12 mb-4">
              <p>Section 2</p>
              <RichTextEditor
                value={htmlDesc11}
                onChange={(val) => handleHtmlInputChange11(val, 'about1')}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="keywords"
                label="keywords"
                value={ad?.keywords?.en ? ad?.keywords[selectedLang] : ''}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}

                {...(errors.keywords && { error: true, helperText: errors.keywords })}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seoh1"
                label="SEO h1"
      
                value={ad?.seoh1[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seoh1 && { error: true, helperText: errors.seoh1 })}
              />
      
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seotitle"
                label="SEO Title"
      
                value={ad?.seotitle[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seotitle && { error: true, helperText: errors.seotitle })}
              />
      
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              <TextField
                name="seometa"
                label="SEO Meta"
      
                value={ad?.seometa[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seometa && { error: true, helperText: errors.seometa })}
              />
      
            </div> */}
          </div>



          {/* <br></br>
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ad.categoryId}
              onChange={handleChange}

            >
              {props.categories.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Departments</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ad.departments}
              onChange={handleChangeForDepartments}
              multiple
            >
              {props.departments.map(item => (
                <MenuItem value={item.id}>{item.title.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ad.branch}
              onChange={handleBranchChangeForServices}

            >
              {props.branches.map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ad.doctor}
              onChange={handleChangeForDoctor}

            >
              {props.doctors.filter((e) => e.role === 'doctor').map(item => (
                <MenuItem value={item.id}>{item.name?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Service</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ad.service}
              onChange={handleChangeForService}

            >
              {props.services.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}

            </Select>
          </FormControl> */}
          <br /><br />
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddAd;