import API from "../utils/api";

export const ACTION_TYPES = {
    AD_CREATE: 'AD_CREATE',
    AD_UPDATE: 'AD_UPDATE',
    AD_DELETE: 'AD_DELETE',
    AD_FETCH: 'AD_FETCH',
    AD_FETCH_ALL: 'AD_FETCH_ALL',
    AD_PAGINATION: 'AD_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.ad().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.AD_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, name = "") => dispatch => {
    API.ad().fetchPagination(page, Math.abs(limit), name)
        .then(res =>{
           console.log('1212',res.data)
            dispatch({
                type: ACTION_TYPES.AD_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.ad().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.AD_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.ad().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.AD_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.ad().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.AD_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.ad().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.AD_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}