import React from "react";
import {
  Route,
  Switch,
  withRouter,
  
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import Dashboard from "../../pages/dashboard/Dashboard";


// map with redux
import Map from "../../pages/map";
import AddForm from "../../pages/map/form/AddForm";
import EditForm from "../../pages/map/form/EditForm";

// user
import User from "../../pages/user";
import Category from "../../pages/category";
import Department from "../../pages/department";
import Contact from "../../pages/contact";
import Order from "../../pages/order";
import Doctor from "../../pages/doctor";
import Blog from "../../pages/blog";
import Pack from "../../pages/pack";
import Staff from "../../pages/staff";
import Offer from "../../pages/offer";
import Giftcard from "../../pages/giftcard";
import Service from "../../pages/service";
import Package from "../../pages/package";
import Product from "../../pages/product";
import Promocode from "../../pages/promocode";
import Coupon from "../../pages/coupon";
import Scoupon from "../../pages/scoupon";
import Event from "../../pages/event";
import Branche from "../../pages/branche";
import Unit from "../../pages/unit";
import Review from "../../pages/review";
import NotificationCenter from "../../pages/notificationcenter";
import Fcm from "../../pages/fcm";
import Doctorbooking from "../../pages/doctorbooking";
import Giftcardorder from "../../pages/giftcardorder";
import Result from "../../pages/result";
import Booking from "../../pages/booking";
import Feedback from "../../pages/feedback";

// user with context
import UserWithContext from "../../pages/user-context";
import AddUserForm from "../../pages/user-context/form/AddUserForm";
import EditUserForm from "../../pages/user-context/form/EditUserForm";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { UserProvider } from "../../pages/user-context/context/UserContext";

function AdminLayout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
              <Switch>
              <Route exact path="/admin/booking/" component={Booking} />
                <Route path="/admin/dashboard" component={Dashboard} />               
                <Route exact path="/admin/map" component={Map} />
                <Route path="/admin/map/add" component={AddForm} />
                <Route path="/admin/map/edit/:id" component={EditForm} />
                <Route exact path="/admin/order/" component={Order} />
                <Route exact path="/admin/user/" component={User} />
                <Route exact path="/admin/category/" component={Category} />
                <Route exact path="/admin/giftcardorder/" component={Giftcardorder} />
                <Route exact path="/admin/doctor/" component={Doctor} />
                <Route exact path="/admin/blog/" component={Blog} />
                <Route exact path="/admin/offer/" component={Offer} />
                
                <Route exact path="/admin/feedback/" component={Feedback} />
                
                <Route exact path="/admin/staff/" component={Staff} />
                <Route exact path="/admin/pack/" component={Pack} />
                <Route exact path="/admin/giftcard/" component={Giftcard} />
                <Route exact path="/admin/service/" component={Service} />
                <Route exact path="/admin/package/" component={Package} />
                <Route exact path="/admin/product/" component={Product} />
                <Route exact path="/admin/promocode/" component={Promocode} />
                <Route exact path="/admin/event/" component={Event} />
                <Route exact path="/admin/review/" component={Review} />
                <Route exact path="/admin/department/" component={Department} />
                <Route exact path="/admin/result/" component={Result} />
                <Route exact path="/admin/coupon/" component={Coupon} />
                <Route exact path="/admin/scoupon/" component={Scoupon} />
                <Route exact path="/admin/branche/" component={Branche} />
                <Route exact path="/admin/unit/" component={Unit} />
                <Route exact path="/admin/notificationcenter/" component={NotificationCenter} />
                <Route exact path="/admin/fcm/" component={Fcm} />
                <Route exact path="/admin/doctorbooking/" component={Doctorbooking} />
                <Route exact path="/admin/contact/" component={Contact} />
                <UserProvider>
                  <Route exact path="/admin/usercontext" component={UserWithContext} />
                  <Route path="/admin/usercontext/add" component={AddUserForm} />
                  <Route path="/admin/usercontext/edit/:id" component={EditUserForm} />
                </UserProvider>

              </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(AdminLayout);
