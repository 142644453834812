import API from "../utils/api";

export const ACTION_TYPES = {
    PACK_CREATE: 'PACK_CREATE',
    PACK_UPDATE: 'PACK_UPDATE',
    PACK_DELETE: 'PACK_DELETE',
    PACK_FETCH: 'PACK_FETCH',
    PACK_FETCH_ALL: 'PACK_FETCH_ALL',
    PACK_PAGINATION: 'PACK_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.pack().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.PACK_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, name = "") => dispatch => {
    API.pack().fetchPagination(page, Math.abs(limit), name)
        .then(res =>{
           console.log('1212',res.data)
            dispatch({
                type: ACTION_TYPES.PACK_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.pack().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.PACK_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.pack().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.PACK_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.pack().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.PACK_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.pack().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.PACK_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}